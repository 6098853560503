import { QueryClientProvider } from '@tanstack/react-query';
import * as React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { queryClient } from 'shared/api/queryClient';
import { Provider as ThemeProvider } from 'shared/components/ui/provider';
import { Device } from 'shared/core/Providers/Device';
import { FileUploadProvider } from 'shared/modules/fileUpload';
import { persistor, store } from 'shared/store';

type Props = React.PropsWithChildren<{}>

export const Providers = React.memo(({ children }: Props) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <Device>
              <FileUploadProvider>
                {children}
              </FileUploadProvider>
            </Device>
          </ThemeProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
});
