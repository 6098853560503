import * as notes from './notes';
import * as posts from './posts';
import * as spaces from './spaces';
import * as spaceTabs from './spaceTabs';
import * as users from './users';

export const options = {
  spaces,
  notes,
  posts,
  users,
  spaceTabs,
};